// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Actions, ISetSearchContext } from "../actions/SearchActions";

export interface IState {
  search: {
    context: string;
  };
}

export type SearchActionsType = ISetSearchContext;

const reducer = (state: IState, action: SearchActionsType) => {
  switch (action.type) {
    case Actions.Search_SetContext: {
      const { context } = action as ISetSearchContext;
      return {...state, search: {...state.search, context} };
    }
    default:
      return state;
  }
};

export default reducer;
