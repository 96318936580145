// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { IntlShape, useIntl } from "react-intl";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

interface INumberWithUnitProps {
  className?: string;
  unit?: string | null;
  value: number | null;
  variant?: Variant | "inherit";
}

const NumberWithUnit = ({
  className,
  unit,
  value,
  variant
}: INumberWithUnitProps) => {
  const intl = useIntl();

  return (
    <Typography
      className={className}
      data-qa="number-with-unit"
      variant={variant}
    >
      {NumberWithUnitService.format(value, unit, intl)}
    </Typography>
  );
};

export class NumberWithUnitService {
  public static format(
    value: number | null,
    unit: string | null | undefined,
    intl: IntlShape
  ) {
    return (unit !== null) && (unit !== undefined)
      ? intl.formatMessage(
          {
            defaultMessage: "{value} {unit}",
            id: `mobile.formatter.number-with-unit`
          },
          {
            value: value !== null 
                            ? intl.formatNumber(value) 
                            : "-",
            unit: unit
          }
        )
      : value !== null
      ? intl.formatNumber(value)
      : "-";
  }
}

export default NumberWithUnit;
