// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { UnknownAction } from "redux";

export enum Actions {
  Search_SetContext = "Search_SetContext"
}

export interface ISetSearchContext extends UnknownAction {
  context: string;
}

export const setSearchContextAction = (context: string): ISetSearchContext => ({
  type: Actions.Search_SetContext,
  context
});
