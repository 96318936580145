// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ILicenseData from "../models/ILicenseData";
import ICommonLicense from "../models/ICommonLicense";

const convertCommonLicensesToData = (
  licenses: ICommonLicense[]
): ILicenseData[] => {
  return licenses.map((license) => {
    return {
      library: license.Name,
      version: null,
      licenses: "",
      licensesUrl: license.Url,
      licensesFileName: license.FileName || null,
      projectUrl: license.Url || null,
      licenseType: "common"
    };
  });
};


export default convertCommonLicensesToData;
