// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { UnknownAction } from "redux";

import { ApiResponse } from "@apm/endpoint";

export enum Actions {
  Data_LoadData = "Data_LoadData",
  Data_SetResponse = "Data_SetResponse",
  Data_RemoveResponse = "Data_RemoveResponse"
}

export interface ISetResponseAction<T> extends UnknownAction {
  key: string;
  response: ApiResponse<T>;
}

export const setResponseAction = <T>(
  key: string,
  response: ApiResponse<T>
): ISetResponseAction<T> => ({
  type: Actions.Data_SetResponse,
  key,
  response
});

export type EndpointServiceFnType = (...args: any[]) => any;

export interface ILoadDataAction extends UnknownAction {
  key: string;
  endpointServiceFn: EndpointServiceFnType;
  args: any[];
}

export const loadDataAction = (
  key: string,
  endpointServiceFn: EndpointServiceFnType,
  ...args: any[]
): ILoadDataAction => ({
  type: Actions.Data_LoadData,
  key,
  endpointServiceFn,
  args
});

export interface IRemoveResponseAction extends UnknownAction {
  key: string;
}

export const removeResponseAction = (key: string): IRemoveResponseAction => ({
  type: Actions.Data_RemoveResponse,
  key
});
