// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import styled from "styled-components";

import { spacingXLarge } from "core/styles/StyleVariables";
import NameplateParameter from "../NameplateParameter";
import IAssetNameplate from "../../models/IAssetNameplate";
import IModelDetails from "common/models/IModelDetails";
import { useIntl } from "react-intl";
import { isNil } from "lodash";

export interface IAssetNameplateItemsProps {
  className?: string;
  nameplate: IAssetNameplate;
}

enum SpecificNameplateFields {
  ManufacturerModel = "ManufacturerModel",
  ModelConfigurationName = "ModelConfigurationName",
  EamEquipmentNumber = "EamEquipmentNumber",
  AssetModelGroupName = "AssetModelGroupName"
}

const isNotEmptySpecificField = (option: string, value: any) => {
  if (!isNil(value) && value !== "") {
    return true;
  }

  return !Object.keys(SpecificNameplateFields).includes(option);
};

const AssetNameplateItems = ({
  className,
  nameplate
}: IAssetNameplateItemsProps) => {
  const nameplateEntries = Object.entries(nameplate).filter((e) => {
    const option = e[0];
    const value = e[1];

    return (
      option !== "Attributes" &&
      option !== "ModelImplementationId" &&
      isNotEmptySpecificField(option, value)
    );
  });
  const attributesEntries = Object.entries(nameplate.Attributes);
  const model: IModelDetails = {
    modelId: nameplate.ModelId || "",
    implementationId: nameplate.ModelImplementationId || ""
  };

  const { formatMessage } = useIntl();

  return (
    <div className={className} data-qa="asset-nameplate-items">
      {nameplateEntries.map((e) => (
        <NameplateParameter
          className="item"
          key={e[0]}
          model={model}
          parameterId={e[0]}
          parameterMessage={{
            defaultMessage: e[0],
            id: `mobile.asset_details.nameplate.${e[0]}`
          }}
          value={
            e[0] === "LifecycleStatus"
              ? formatMessage({
                  id: `mobile.asset_details.nameplate.${e[0]}.${e[1]}`,
                  defaultMessage: e[1]
                })
              : e[1]
          }
        />
      ))}
      {attributesEntries.map((e) => (
        <NameplateParameter
          className="item"
          key={e[0]}
          model={model}
          parameterId={e[0]}
          parameterMessage={{
            defaultMessage: e[0],
            id: e[0]
          }}
          value={e[1]}
        />
      ))}
    </div>
  );
};

const StyledAssetNameplateItems = styled(AssetNameplateItems)`
  & .item {
    margin-bottom: ${spacingXLarge};
  }
`;

export default StyledAssetNameplateItems;
