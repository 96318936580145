// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import endpoint, { getApiUrl } from "@apm/endpoint";

import { loadDataAction } from "../actions/DataActions";

const localesApiUrl = "translations/{locale}";

export const dataKey = "translations";

export const loadTranslationsAction = (locale: string[]) => {
  const url = getApiUrl(localesApiUrl, [
    { name: "locale", value: locale.join(",") || "" },
  ]);
  return loadDataAction(dataKey, endpoint.get, url);
};