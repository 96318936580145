// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const iso8601DateFormatRegex: RegExp = /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])T([01]\d|2[0-3]):[0-5]\d:[0-5]\d(\.\d+)?([zZ]|[+-]([01]\d|2[0-3]):[0-5]\d)?$/;

const isDateString = (value: string | null | undefined) => {
  if (value === null || value === undefined) return false;
  return iso8601DateFormatRegex.test(value);
};

export default isDateString;
