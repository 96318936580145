// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Data, PrimitiveArray } from "billboard.js";
import { isDate } from "lodash";

export const DEFAULT_MAX_ZOOM = 500;

type DateValue = Date | string | number;

const getTime = (time: DateValue) => new Date(time).getTime();

const getMinTimeBetweenValues = (timeRange: PrimitiveArray) => {
  let minTime = Infinity;

  for (let i = 1; i < timeRange.length; i++) {
    const current = timeRange[i];
    const prev = timeRange[i - 1];

    if (!isDate(current) || !isDate(prev)) {
      return null;
    } 

    const time = getTime(current) - getTime(prev);
    if (time < minTime) {
      minTime = time;
    }
  }

  return minTime === Infinity ? null : minTime;
};

const getTotalTime = (timeRange: PrimitiveArray) => {
  const first = timeRange[0];
  const last = timeRange[timeRange.length - 1];

  if (!isDate(first) || !isDate(last)) {
    return null;
  }

  return getTime(last) - getTime(first);
};

const getMaxZoom = (data: Data) => {
  const columns = data?.columns;

  if (!columns || columns.length === 0) {
    return DEFAULT_MAX_ZOOM;
  }

  // get time range without x axis
  const timeRange = columns[0].slice(1);

  if (timeRange.length <= 1) {
    return DEFAULT_MAX_ZOOM;
  }

  const minTime = getMinTimeBetweenValues(timeRange);
  const totalTime = getTotalTime(timeRange);

  if (minTime === null || totalTime === null) {
    return DEFAULT_MAX_ZOOM;
  }

  // zoom to be proportional to the smallest time difference but not less than DEFAULT_MAX_ZOOM
  return Math.max(Math.round(totalTime / minTime), DEFAULT_MAX_ZOOM);
};

// the first value should be 1 to have the default zoom as it is
const getZoomExtent = (configuration: Data): [1, number] => {
  const maxZoom = getMaxZoom(configuration);

  return [1, maxZoom];
};

export default getZoomExtent;
