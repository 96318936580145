// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Statuses } from "@apm/endpoint";

import ITenant from "core/models/ITenant";
import getUserTenantSelector from "core/selectors/getUserTenantSelector";
import getTenantsSelector from "core/selectors/getTenantsSelector";
import LocaleService from "core/services/LocaleService";
import PanelHeader from "features/sidepanel/components/PanelHeader";
import PanelContent from "features/sidepanel/components/PanelContent";

import ContentProvider from "./ContentProvider";
import MaterialUiProvider from "./MaterialUiProvider";
import RouterProvider from "./RouterProvider";
import { loadEnvironmentAction } from "../actions/EnvironmentActions";
import { loadUserAction } from "../actions/UserActions";
import { loadVersionAction } from "../actions/VersionActions";
import {
  loadTenantsAction,
  setUserTenantAction
} from "../actions/TenantsActions";
import { loadTranslationsAction } from "../actions/TranslationsActions";
import Drawers from "../components/Drawers";
import IntlProvider from "../components/IntlProvider";
import useApp from "../hooks/useApp";
import useDrawer from "../hooks/useDrawer";
import TenantService from "../services/TenantService";

const App = () => {
  const { appStatus } = useApp();
  const dispatch = useDispatch();
  const { setDrawerContent, setDrawerHeader } = useDrawer("right");
  const userTenant = useSelector(getUserTenantSelector);
  const tenants = useSelector(getTenantsSelector);

  useLayoutEffect(() => {
    setDrawerHeader(() => <PanelHeader />);
    setDrawerContent(() => <PanelContent />);
  }, [setDrawerContent, setDrawerHeader]);

  useEffect(() => {
    dispatch(loadVersionAction());
    dispatch(loadTenantsAction());
  }, [dispatch]);

  useEffect(() => {
    if (tenants?.status === Statuses.Succeeded) {
      const switchTenant = (tenant: ITenant | undefined) => {
        dispatch(setUserTenantAction(tenant));
      };

      TenantService.setupTenant(
        userTenant,
        tenants.data as ITenant[],
        switchTenant
      ).then(() => {
        const locales = LocaleService.getBrowserLocales();
        dispatch(loadEnvironmentAction());
        dispatch(loadTranslationsAction(locales));
        dispatch(loadUserAction());
      });
    }
  }, [dispatch, tenants, userTenant]);

  return (
    <MaterialUiProvider>
      <ContentProvider status={appStatus}>
        <IntlProvider>
          <RouterProvider>
            <Drawers />
          </RouterProvider>
        </IntlProvider>
      </ContentProvider>
    </MaterialUiProvider>
  );
};

export default App;
